import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalConstants } from '../global-constants';

export interface Config {
  previsao: string
  noticias: string 
  calendario:string
  covid19:string
  solicitacoes:string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  endpoint = GlobalConstants.url+'services/config';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private httpClient: HttpClient) { }

   
  getConfig(): Observable<Config[]> {
    return this.httpClient.get<Config[]>(this.endpoint)
      .pipe(
        tap(users => console.log(users)),
        catchError(this.handleError<Config[]>('Get config', []))
      );
  }
 

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }  
}
