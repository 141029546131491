import { Component } from '@angular/core';
import { GlobalConstants } from './global-constants';
import { StorageService } from './services/storage.service';
import { User, UsersService } from './services/users.service';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { PushNotifications } from '@capacitor/push-notifications';
import { ConfigService } from './services/config.service';
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  splash = GlobalConstants.splashScreen;
  user: User;
  isIos: boolean = false;
  public Config: any = {};

  public appPages = [
    { title: 'Inicio', url: 'inicio', icon: 'home' },
    { title: 'Meu Perfil', url: 'cadastro', icon: 'person' },
    { title: 'Minhas Solicitações', url: 'mantis-solicitados', icon: 'chatbubble-ellipses' },
    { title: 'Sobre o aplicativo', url: 'politica', icon: 'newspaper' },
    { title: 'Sair', url: 'logout', icon: 'exit' },
    { title: 'Login', url: 'login', icon: 'enter' }
  ];
  public labels = [];

  constructor(private storage: StorageService,
    private stor: Storage, public platform: Platform,
    public userService: UsersService,
    private cService: ConfigService) {

    storage.init();
    if (this.platform.is('ios')) {
      this.isIos = true;
     
    }
    
   
    this.verificarLogin();

    this.config();

    const addListeners = async () => {
      await PushNotifications.addListener('registration', token => {
        console.info('Registration token: ', token.value);
        try {
          this.userService.verificarLogin().then((val) => {
            this.refreshToken(token.value).then(() => { });
          });
        } catch (error) { }
      });

      await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
      });
    }

    try {
      addListeners();
    } catch (e) {

    }


    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    }

    try {
      registerNotifications();
    } catch (e) {

    }



    const getDeliveredNotifications = async () => {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      console.log('delivered notifications', notificationList);
    }

    try {
      getDeliveredNotifications();
    } catch (e) {

    }


  }
   
  async config() {
    this.cService.getConfig().subscribe((response) => {
      var array: any = response;
      this.Config = array[0];
    });
  }

  async verificarLogin() {
    const userString = await this.stor.get('login-app-pmtr');
    if (userString !== null && userString !== '') {
      this.user = JSON.parse(userString) as User;
      this.userService.setUser(this.user);
      //console.log(this.user);
    } else {
      this.user = null;
      this.userService.setUser(null);
    }
  }

  async refreshToken(token: string) {
    const userString = await this.stor.get('login-app-pmtr');
    if (userString !== null && userString !== '') {
      this.user = JSON.parse(userString) as User;
      this.userService.setUser(this.user);
      this.user.token = token;
      this.userService.refreshToken(this.user).subscribe(data => {
        console.info('Registration token Refreshed: ');
      });
    }
  }

  verificarUrl(url: string): boolean {

    if (url === 'logout' && this.userService.getUser() === null)
      return false;
    if (url === 'mantis-solicitados' && this.userService.getUser() === null)
      return false;
    if (url === 'perfil' && this.userService.getUser() === null)
      return false;
    if (url === 'covid19' && this.userService.getUser() === null
    ) {
      if (this.Config !== null && this.Config.covid19 === 'TRUE') {
        return true;
      } else {
        return false;
      }
      return false;
    }
    else if (url === 'login' && this.userService.getUser() !== null)
      return false;
    else
      return true;
  }

}
