import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { GlobalConstants } from '../global-constants';

export class User {
  id: string
  nome: string
  senha: string
  email: string
  cpf: string
  dataString: string
  numeroCelular: string
  logradouro: string
  idBairro: number
  bairro: string
  complemento: string
  numero: number
  token:string

  receberNotificacoesNoticias:boolean
	receberNotificacoesImportantes:boolean
  temAceiteTermo:boolean
}

export class Login {
  login: string
  senha: string
  email:string
  data:string
  cpf:string
  erro:string
}

export class Aceite{
  aceite:string
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  endpoint = GlobalConstants.url+'services/bairros';
  endpoint_perfil = GlobalConstants.url+'users/';
  endpoint_termo = GlobalConstants.url+'services/aceite';


  private user: User;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private httpClient: HttpClient, private storage: Storage) { }


  getBairros(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.endpoint)
      .pipe(
        tap(users => console.log(users)),
        catchError(this.handleError<User[]>('Get bairro', []))
      );
  }

  getAceite(): Observable<Aceite[]> {
    return this.httpClient.get<Aceite[]>(this.endpoint_termo)
      .pipe(
        tap(users => console.log(users)),
        catchError(this.handleError<Aceite[]>('Get Aceite', []))
      );
  }

  getUserApi(id:string): Observable<User> {
    return this.httpClient.get<User>(this.endpoint_perfil+id)
      .pipe(
        tap(users => console.log(users)),
        catchError(this.handleError<User>('Get bairro', new User()))
      );
  }

 
  refreshSenha(user: Login): Observable<any> {

    return this.httpClient.post(GlobalConstants.url+"users/sendPass", user, this.httpOptions);
  }

  refreshToken(user: User): Observable<any> {

    return this.httpClient.post(GlobalConstants.url+"users/push", user, this.httpOptions);
  }

  setUser(user: User) {
    this.user = user;
  }

  verificarUser() :boolean{
    if (this.user === null || this.user.id === '')
      return false;
    else
      return true;
  }


  getUser() {
    return this.user;
  }

  verificarLogin():Promise<boolean>{

    return this.storage.get('login-app-pmtr');
  }

  setRedirect(){
    this.storage.set('redirect','/mantis-solicitados/');
  }



  createUser(user: User): Observable<any> 
  {
    if(this.user === null || this.user.id === '')
      return this.httpClient.post(GlobalConstants.url+"users/add", user, this.httpOptions);
    else
      return this.editUser(user);
  }

  editUser(user: User): Observable<any> {

    return this.httpClient.post(GlobalConstants.url+"users/edit", user, this.httpOptions);
  }

  login(user: Login): User {

    this.httpClient.post(GlobalConstants.url+"users/", user, this.httpOptions)
      .subscribe(data => {
        return data;
      }, error => {
        return null;
      });
    return null;
  }




  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
