import { Component, OnInit } from '@angular/core'; 
import { WordpressService } from '../services/wordpress.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wordpress',
  templateUrl: './wordpress.component.html',
  styleUrls: ['./wordpress.component.scss'],
})
export class WordpressComponent implements OnInit {

  public  Users: any = [];

  constructor( 
    private userCrudService: WordpressService,
    private router: Router
  ) { }

  ionViewDidEnter() {
    this.userCrudService.getNews().subscribe((response) => {
      this.Users = response;
    })
  }

  ngOnInit() {
    this.userCrudService.getNews().subscribe((response) => {
      this.Users = response;
    });
  }

}
